<script>
  import { createEventDispatcher } from 'svelte'
  import Calendar from '@event-calendar/core'
  import TimeGrid from '@event-calendar/time-grid'
  import DayGrid from '@event-calendar/day-grid'
  import Interaction from '@event-calendar/interaction'
  import UserAvatar from '@/components/UserAvatar.svelte'

  import { tasks, loadTasks, updateTask, getTask } from '@/stores/TaskStore'
  import { boardStore, getTaskStatusClasses } from '@/stores/TaskBoardStore'
  import dayjs from 'dayjs'
  import { taskTemplate } from '@/pages/Tasks/components/taskTemplate'

  export let citizen
  export let users

  let calendar
  let start_time
  let end_time

  const dispatch = createEventDispatcher()

  boardStore.subscribe(() => {
    if (start_time != null && end_time != null)
      loadTasks(start_time, end_time, citizen.id)
  })

  function formatTasks(tasks) {
    let formattedTasks = []

    tasks.forEach((task) => {
      formattedTasks.push({
        id: task.id,
        title: task.name,
        allDay: false,
        start: task.start_time,
        end: task.end_time,
        classNames: ['sg-task', getTaskStatusClasses(task)],
        textColor: 'black',
        styles: 'font-size: 12px',
      })
    })

    return formattedTasks
  }

  function formatTaskContent(eventInfo) {
    let task = getTask(parseInt(eventInfo.event.id))
    if (task == null) return

    return taskTemplate({
      citizen: users.find((user) => user.id === task.user_id),
      label: task.name,
      start_time: task.start_time,
      end_time: task.end_time,
    })
  }

  function changeTaskTime(boardTask) {
    let task = getTask(parseInt(boardTask.id))
    task.isRecurring = task.recurring != null

    updateTask({
      id: boardTask.id,
      start_time: dayjs(boardTask.start).format(),
      end_time: dayjs(boardTask.end).format(),
      isRecurring: task.isRecurring,
    })

    loadTasks(start_time, end_time, citizen.id)
  }

  function selectTask(eventInfo) {
    if ($boardStore.userGrabbingTasks) {
      boardStore.addGrabbedTask({
        id: parseInt(eventInfo.event.id),
        start_time: eventInfo.event.start,
        end_time: eventInfo.event.end,
      })
    } else {
      let task = getTask(parseInt(eventInfo.event.id))

      $boardStore.selectedTask = task
      $boardStore.showTaskModal = true
    }
  }

  let plugins = [TimeGrid, DayGrid, Interaction]
  $: options = {
    view: 'timeGridWeek',
    events: formatTasks($tasks),
    editable: true,
    longPressDelay: 0,
    locale: 'da-DK',
    scrollTime: '06:00:00',
    allDaySlot: false,
    slotHeight: 72,
    headerToolbar: {
      start: 'today prev,next',
      center: '',
      end: 'timeGridWeek timeGridDay',
    },
    height: '800px',
    buttonText: {
      close: 'Close',
      dayGridMonth: 'month',
      resourceTimeGridDay: 'resources',
      resourceTimeGridWeek: 'resources',
      resourceTimelineDay: 'timeline',
      resourceTimelineMonth: 'timeline',
      resourceTimelineWeek: 'timeline',
      timeGridDay: 'Plan i dag',
      timeGridWeek: 'Ugeplan',
      today: 'Denne uge',
    },
    eventContent: (eventInfo) => {
      if (parseInt(eventInfo.event.id) == null) return

      return {
        html: formatTaskContent(eventInfo),
      }
    },
    datesSet: (dateInfo) => {
      start_time = dateInfo.start
      end_time = dateInfo.end
      // TODO: Should be refactored in branch: replacing-floweffect-board
      $boardStore.startTime = dateInfo.start
      $boardStore.endTime = dateInfo.end
      dispatch('datesChanged')
      /////
      loadTasks(dateInfo.start, dateInfo.end, citizen.id)
    },
    eventDrop: (eventInfo) => {
      changeTaskTime(eventInfo.event)
    },
    eventResize: (eventInfo) => {
      changeTaskTime(eventInfo.event)
    },
    eventClick: (eventInfo) => {
      selectTask(eventInfo)
    },
  }
</script>

<div style="margin-top: 50px;">
  <h1>
    <UserAvatar user={citizen} />
    {citizen.name}
  </h1>
  <h3>Ugeplan - uge {dayjs(start_time).week()}</h3>
  <Calendar bind:this={calendar} {plugins} {options} />
</div>

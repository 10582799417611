import dayjs from 'dayjs'
import { firstName } from '../utils'

export function taskTemplate(task, useLabel = true) {
  let label = useLabel ? task.label : task.name
  let start_time = dayjs(task.start_time).format('HH:mm')
  let end_time = dayjs(task.end_time).format('HH:mm')
  let citizenInfo = task.citizen
    ? `
        <div style="display: flex; gap: 5px">
          <img src="${
            task.citizen.avatar.thumb.url
          }" height="16px" width="16px" class="img-circle" />
          <div class="subject-name">${firstName(task.citizen.name)}</div>
        </div>
        `
    : ''

  return `
    <div class="task-card-container" style="overflow: hidden">
      <div>
        <div class="task-card-content"><strong>${label}</strong></div>
        ${citizenInfo} 
      </div>
      <div class="task-card-subject">
        <small class="text-muted">${start_time} - ${end_time}</small>
      </div>
    </div>
  `
}

<script>
  import UserAvatar from '../../../components/UserAvatar.svelte'
  import IncidentComment from '../../Events/components/IncidentComment.svelte'
  import ImpactScoreCircles from '../../Events/components/ImpactScoreCircles.svelte'
  import {
    startDate,
    endDate,
  } from '../../../components/ReportsNavigation/store.js'
  import dayjs from 'dayjs'

  export let incidentsWithCommentsCount
  export let incidentsWithComments
  export let showCompanyName = false

  let start_date
  let end_date

  startDate.subscribe((value) => {
    start_date = dayjs(value).format('YYYY-MM-DD')
  })

  endDate.subscribe((value) => {
    end_date = dayjs(value).format('YYYY-MM-DD')
  })
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h3>Kommentarer til hændelser</h3>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-xs-12">
      {#if incidentsWithComments.length > 0}
        <div class="grid-col">
          {#each incidentsWithComments as incident}
            <div class="grid">
              <div style="display: flex; gap: 1rem;">
                <UserAvatar user={incident.user} size="25" />
                <strong>
                  <a
                    href="/events?incident_search[user_id]={incident.user
                      .id}&start_date={start_date}&end_date={end_date}"
                  >
                    <div>{incident.user.name}</div>
                    {#if showCompanyName}
                      <small class="text-muted">{incident.company.name}</small>
                    {/if}
                  </a>
                </strong>
              </div>
              <div class="text-center">
                <ImpactScoreCircles score={incident.impact_score} with_text />
              </div>
              <div>
                <IncidentComment {incident} length={80} />
              </div>
              <div>
                <div class="hidden-xs">
                  <a
                    href="/events/{incident.id}"
                    class="btn btn-default pull-right"
                  >
                    Detaljer
                  </a>
                </div>
                <div class="visible-xs">
                  <a
                    href="/events/{incident.id}"
                    class="btn btn-default btn-block"
                  >
                    Detaljer
                  </a>
                </div>
              </div>
            </div>
          {/each}
          {#if incidentsWithCommentsCount > 10}
            <a
              href="/events?incident_search[comments]=true&start_date={start_date}&end_date={end_date}"
              class="btn btn-default"
            >
              Se alle hændelser {incidentsWithCommentsCount} hændelser med kommentarer
            </a>
          {/if}
        </div>
      {:else}
        <div class="alert alert-warning">
          Der er ingen kommentarer til hændelser i perioden.
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }

  .grid-col {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
</style>

<script>
  import { onMount, createEventDispatcher } from 'svelte'
  import { SvelteGantt, SvelteGanttTable } from 'svelte-gantt/svelte'
  import dayjs from 'dayjs'
  import axios from 'axios'
  import { options } from '@/stores/OptionsStore'
  import { boardStore, updateBoard, reloadTasks } from '@/stores/TaskBoardStore'

  let gantt

  const dispatch = createEventDispatcher()

  // Updates TimeRanges bar every 10 seconds
  function setTimeRanges() {
    return [
      {
        id: 0,
        from: dayjs(),
        to: dayjs(),
        classes: 'time-range-bar',
        label: dayjs().format('HH:mm'),
      },
    ]
  }

  // Making reload every 12 hours to push updates to client screens
  setInterval(() => {
    if ($boardStore.safeToReload) {
      window.location.reload()
    }
  }, 43_200_000)

  // Update board and vertical line every minute
  let timeRanges = setTimeRanges()
  setInterval(() => {
    let lastUpdatedAt = dayjs(timeRanges[0].from).format('YYYY-MM-DD HH:mm')

    if (lastUpdatedAt != dayjs().format('YYYY-MM-DD HH:mm')) {
      // Updatin vertical line (time range)
      timeRanges = setTimeRanges()

      // Update board time
      if ($boardStore.stickyTime) {
        boardStore.resetTime()
      }

      $options = {
        ...$options,
        timeRanges: timeRanges,
        from: dayjs($boardStore.startTime),
        to: dayjs($boardStore.endTime),
      }
      updateBoard()
    }
  }, 10_000)

  boardStore.subscribe(() => {
    $options = {
      ...$options,
      rows: $boardStore.rows,
      tasks: $boardStore.boardTasks,
      from: dayjs($boardStore.startTime),
      to: dayjs($boardStore.endTime),
    }
  })

  $options = {
    layout: 'expand',
    rows: $boardStore.rows,
    timeRanges,
    tasks: $boardStore.boardTasks,
    columnOffset: 15,
    magnetOffset: 15,
    rowHeight: 150,
    rowPadding: 6,
    columnStrokeColor: '#ffffff',
    columnStrokeWidth: 1,
    useCanvasColumns: false,
    headers: [{ unit: 'hour', format: 'H:mm' }],
    fitWidth: true,
    minWidth: 5000,
    from: dayjs($boardStore.startTime),
    to: dayjs($boardStore.endTime),
    tableHeaders: [{ title: '' }],
    tableWidth: 240,
    ganttTableModules: [SvelteGanttTable],
  }

  function updateTask(taskId, taskData, path) {
    if (taskData.user_id == 9999) {
      taskData = {
        ...taskData,
        user_id: null,
      }
    }
    axios
      .put(`/tasks/${path}/${taskId}`, taskData)
      .then(() => {
        updateBoard()
        dispatch('updateUsers')
      })
      .catch((error) => {
        console.log('ERROR:', error)
      })
  }

  onMount(() => {
    gantt.api.tasks.on.changed((task) => {
      let taskModel = task[0].task.model
      let taskData = {
        user_id:
          taskModel.card_type == 'user'
            ? taskModel.resourceId
            : taskModel.user_id,
        citizen_id:
          taskModel.card_type == 'citizen'
            ? taskModel.resourceId
            : taskModel.citizen_id,
        start_time: dayjs(taskModel.from).format('DD/MM/YYYY HH:mm'),
        end_time: dayjs(taskModel.to).format('DD/MM/YYYY HH:mm'),
        recurring: taskModel.recurring,
      }
      if (taskModel.recurring != null) {
        updateTask(
          task[0].task.model.task_id,
          { recurring_task: taskData },
          'recurring_tasks',
        )
      } else {
        updateTask(
          task[0].task.model.task_id,
          { single_task: taskData },
          'single_tasks',
        )
      }
    })

    gantt.api.tasks.on.select((task) => {
      if ($boardStore.taskGrabbingActivated) {
        if (task[0].model.user_id == null) {
          if (!$boardStore.grabbedTasks.includes(task[0].model.id)) {
            boardStore.addGrabbedTask({
              id: task[0].model.id,
              start_time: task[0].model.from,
              end_time: task[0].model.to,
            })
            reloadTasks()
          }
        }
      } else {
        let selectedTask = $boardStore.tasks.find((ts) => {
          return ts.id === task[0].model.id
        })
        $boardStore.selectedTask = selectedTask
        $boardStore.showTaskModal = true
      }
    })
  })

  $: console.log(dayjs.locale())
</script>

<div id="flow-effect">
  <SvelteGantt bind:this={gantt} {...$options} />
</div>

<style>
  :global(.time-range-bar) {
    background-image: none !important;
    color: #4e9e5d !important;
    border-left: 1px dashed #4e9e5d !important;
    border-right: 1px dashed #4e9e5d !important;
    z-index: 1100 !important;
  }

  :global(.time-range-bar > .sg-time-range-label) {
    background: none !important;
    padding-left: 35px !important;
    position: absolute !important;
  }
</style>

<script>
  import ImpactScoreCircles from './ImpactScoreCircles.svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import IncidentComment from './IncidentComment.svelte'
  import dayjs from 'dayjs'

  export let incident
  export let anonymous = false
  export let forChecklist = false
  export let forChecklistToggle = null
  export let forChecklistSelected = false
  export let showCompanyName = false
</script>

<div class="panel panel-default">
  <div class="panel-body">
    <div class="row flex">
      <div class="col-xs-12 visible-xs">
        <h4>
          <a
            href="/events?incident_search[event_category_id]={incident.event_category_id}"
          >
            {incident.event_category.name}</a
          >
        </h4>
      </div>
      {#if !anonymous}
        <div class="col-xs-7 col-sm-2">
          <div class="row">
            <div class="col-xs-2 col-sm-2">
              <UserAvatar user={incident.user} size="25" />
            </div>
            <div class="col-xs-10 col-sm-10">
              <strong>
                <a
                  href="/workday_registration_reports/user_report/{incident.user_id}"
                  >{incident.user.name}</a
                >
              </strong>
              <small class="text-muted">
                <div>
                  <div class="glyphicon glyphicon-calendar" />
                  {dayjs(incident.day).format('ll')}
                </div>
                {#if showCompanyName}
                  <div>{incident.company.name}</div>
                {/if}
              </small>
            </div>
          </div>
        </div>
      {/if}
      <div class="col-sm-2 hidden-xs">
        <a
          href="/events?incident_search[event_category_id]={incident.event_category_id}"
          class="btn incident-category-name"
        >
          {incident.event_category.name}
        </a>
        {#if anonymous}
          <small class="text-muted">
            <br />
            <div class="glyphicon glyphicon-calendar" />
            {dayjs(incident.created_at).format('ll')}
          </small>
        {/if}
        {#if incident.impact_score >= 4}
          <span
            class="text-danger pull-right hidden-xs hidden-sm"
            style="margin-top: 10px;"
          >
            <div class="glyphicon glyphicon-warning-sign" />
          </span>
        {/if}
      </div>
      <div class="col-xs-5 col-sm-4 text-right visible-xs" style="padding-left: 0px">
        <ImpactScoreCircles score={incident.impact_score} />
      </div>
      <div class="col-xs-12 col-sm-3 hidden-xs">
        <br class="visible-xs" />
        {#if incident.impact_score >= 4}
          <span class="text-danger pull-right visible-sm">
            <div class="glyphicon glyphicon-warning-sign" />
          </span>
        {/if}
        <ImpactScoreCircles score={incident.impact_score} with_text={true} />
      </div>
      <div class="col-xs-12 visible-xs comment">
        <br />
        <IncidentComment {incident} />
      </div>
      <div class="{anonymous ? 'col-sm-5' : 'col-sm-3'} hidden-xs comment">
        <IncidentComment {incident} />
      </div>
      <div class="col-xs-12 col-sm-2">
        <br class="visible-xs" />
        {#if !forChecklist}
          <a href="/events/{incident.id}" class="btn btn-block btn-default">
            {#if incident.impact_score >= 4}
              <span class="text-danger hidden-sm hidden-md hidden-xl hidden-lg">
                <div class="glyphicon glyphicon-warning-sign" />
              </span>
            {/if}
            Detaljer
          </a>
        {:else}
          <button
            class="btn {forChecklistSelected
              ? 'btn-warning'
              : 'btn-default'} btn-block"
            on:click={forChecklistToggle(incident.id)}
            >{forChecklistSelected ? 'Fravælg' : 'Vælg'}</button
          >
        {/if}
      </div>
    </div>
    <hr class="visible-xs" />
  </div>
</div>

<style>
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .comment {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 100%;
  }
</style>

<script>
  import ImprovementArrow from './ImprovementArrow.svelte'
  import ImpactScoreCircles from '../../Events/components/ImpactScoreCircles.svelte'

  export let eventCategoriesByImpact
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <div class="grid-col">
        {#each eventCategoriesByImpact as category (category.name)}
          <div class="grid">
            <div>
              <h4>{category.name}</h4>
            </div>
            <div class="improvement">
              <ImprovementArrow
                currentCount={category.incidents_count}
                priorCount={0}
              />
              <strong>{category.incidents_count}</strong> Hændelser
            </div>
            <div>
              <ImpactScoreCircles score={category.avg_impact} with_text />
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>

<style>
  .improvement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }

  .grid-col {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
</style>

import moment from 'moment'
import axios from 'axios'

export function time(input) {
  return moment(input, 'HH:mm')
}

export function dateTime(input) {
  return moment(input, 'YYYY-MM-DD HH:mm')
}

export function getCheckedInUsers() {
  axios
    .get('/user/checked_in/')
    .then((response) => {
      console.log(response.data)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export function getUrlTimeParams(store) {
  return `?start_time=${store.startTime}&end_time=${store.endTime}`
}

export function firstName(name) {
  return name.split(' ')[0]
}

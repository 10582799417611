<script>
  import { eventForm } from '@/stores/EventFormStore'
</script>

<div class="row" style="margin-top: 6rem">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    {#if $eventForm.impact_score <= 3}
      <h2>Har du en kommentar til hændelsen?</h2>
      <textarea bind:value={$eventForm.comment} class="form-control" rows="5" />
    {:else}
      <h2 style="margin-bottom: 4rem">Uddybende spørgsmål</h2>
      <div class="high-impact-descriptions">
        <div>
          <label for="context-of-incident">
            Hvor og i hvilken sammenhæng opstod hændelsen?
          </label>
          <input
            type="text"
            name="context-of-incident"
            id="context-of-incident"
            class="form-control"
            bind:value={$eventForm.context_of_incident}
          />
        </div>
        <div>
          <label for="who-was-present">
            Hvem, hvis andre, var til stede da hændelsen opstod?
          </label>
          <input
            type="text"
            name="who-was-present"
            id="who-was-present"
            class="form-control"
            bind:value={$eventForm.who_was_present}
          />
        </div>
        <div>
          <label for="description"> Beskriv hændelsen med dine egne ord </label>
          <textarea
            name="description"
            id="description"
            class="form-control"
            rows="3"
            placeholder="Gerne så faktuelt som muligt"
            bind:value={$eventForm.description}
          />
        </div>
        <div>
          <label for="how-it-could-have-been-avoided">
            Reflekter: Kunne hændelsen være undgået? Hvordan?
          </label>
          <textarea
            name="how-it-could-have-been-avoided"
            id="how-it-could-have-been-avoided"
            class="form-control"
            rows="3"
            placeholder="Del gerne dine umiddelbare overvejelser"
            bind:value={$eventForm.how_it_could_have_been_avoided}
          />
        </div>
        <div class="text-muted text-center">
          <div class="glyphicon glyphicon-info-sign" />
          Husk at overveje, om du bør lave en skadesanmeldelse
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  h2 {
    text-align: center;
  }

  .high-impact-descriptions {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
</style>
